.circle-div{
    position:relative;
    width:100%;
    height:100%;
    
    overflow:hidden;
}
.circle-div:after{
    content:'';
    position:absolute;
    left:0; right: 0;
    top:0; bottom: 0;
    margin: auto;
    border-radius:100%;
    width:600px; height:600px;
    box-shadow: 0px 0px 0px 2000px #000000;
    background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


@media screen and (max-width: 475px) {
    .circle-div:after {
        width: 350px;
        height: 350px;
    }
}

