.rotated-text {
  transform: rotate(-90deg);
}

.hero2-img {
  background-image: url("http://localhost:3000/images/beachdays-1.jpg");
  background-size: cover;
}

.hero-text {
    font-size: 100px;
    font-weight: 700;
}