.tv-knob {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/knob.png);
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 3;
  transform-origin: -10% 5%;
}

.tv-shit {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/tv-w_out-screen.png),
    url(https://minters.s3.us-west-2.amazonaws.com/adz/screen.png);
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 2;
}
.tv-shit-no-bg {
  /* background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/tv-w_out-screen.png),
    url(https://minters.s3.us-west-2.amazonaws.com/adz/screen.png); */
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 2;
}

.tv-channel {
  background-position: center, center;
  background-size: cover, cover;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.static-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/static.gif);
}

.one-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/1.gif);
}

.two-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/2.gif);
}

.three-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/3.gif);
}

.four-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/4.gif);
}

.five-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/5.gif);
}

.six-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/6.gif);
}

.seven-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/7.gif);
}

.eight-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/8.gif);
}

.nine-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/9.gif);
}

.ten-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/10.gif);
}

.eleven-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/11.gif);
}

.twelve-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/12.gif);
}

.thirteen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/13.gif);
}

.fourteen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/14.gif);
}

.fifteen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/15.gif);
}

.sixteen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/16.gif);
}

.seventeen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/17.gif);
}

.eighteen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/18.gif);
}

.nineteen-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/19.gif);
}

.twenty-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/20.gif);
}

.twenty-one-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/21.gif);
}

.twenty-two-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/22.gif);
}

.twenty-three-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/23.gif);
}

.twenty-four-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/24.gif);
}

.twenty-five-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/25.gif);
}

.twenty-six-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/26.gif);
}

.twenty-seven-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/27.gif);
}

.twenty-eight-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/28.gif);
}

.twenty-nine-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/29.gif);
}

.thirty-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/30.gif);
}

.thirty-one-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/31.gif);
}

.thirty-two-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/32.gif);
}

.thirty-three-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/33.gif);
}

.thirty-four-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/34.gif);
}

.thirty-five-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/35.gif);
}

.thirty-six-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/36.gif);
}

.thirty-seven-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/37.gif);
}

.thirty-eight-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/38.gif);
}

.thirty-nine-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/39.gif);
}

.forty-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/40.gif);
}

.forty-one-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/41.gif);
}

.forty-two-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/42.gif);
}

.forty-three-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/43.gif);
}

.forty-four-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/44.gif);
}

.forty-five-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/45.gif);
}

.forty-six-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/46.gif);
}

.forty-seven-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/47.gif);
}

.forty-eight-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/48.gif);
}

.forty-nine-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/49.gif);
}

.fifty-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/50.gif);
}

.fifty-one-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/51.gif);
}

.fifty-two-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/52.gif);
}

.fifty-three-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/53.gif);
}

.fifty-four-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/54.gif);
}

.fifty-five-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/55.gif);
}

.fifty-six-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/56.gif);
}

.fifty-seven-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/57.gif);
}

.fifty-eight-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/58.gif);
}

.fifty-nine-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/59.gif);
}

.sixty-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/60.gif);
}

.sixty-one-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/61.gif);
}

.sixty-two-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/62.gif);
}

.sixty-three-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/63.gif);
}

.sixty-four-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/64.gif);
}

.no-monitor {
  height: 100vh !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.blank-screen {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 3rem;
  z-index: 10;
}

.bb {
  width: fit-content;
}

.vid-chan {
  object-fit: cover;
}

.ticker {
  padding: 0 !important;
  padding-bottom: 4rem !important;
}
