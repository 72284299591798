@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;1,600;1,700&family=Ubuntu+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('./scss/_mixins.scss');
@import url('./scss/_devices.scss');

$mobile: 400px;
$mobile-lg: 520px;
$tablet: 768px;
$desktop-sm: 992px;
$desktop: 1200px;
$desktop-lg: 1380px;
$desktop-max: 1504px;
/* * *
* for setting styles based on
* min width. prefer over max-width (mq-max)
* */
@mixin mq($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (min-width: $value) {
    @content;
  }
}
/* * *
* for setting styles based on
* max width. prefer min-width (mq)
* */
@mixin mq-m($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (max-width: $value - 1) {
    @content;
  }
}
// Override default variables before the import
$theme-colors: (
  'primary': rgb(106, 206, 196),
  'danger': #ff4136,
  'orange': #ffa136,
  'black': #000000,
  'grey': #959595,
  'white': #ffffff,
  'light-blue': #6ec8fe,
  'navy': rgb(26, 34, 40),
  'blue': rgb(62, 141, 185),
  'hellothere': rgb(180, 74, 150),
  'hugo-blue': #347bd3,
  'hugo-yellow': rgb(246, 221, 143),
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://use.typekit.net/oqv4gmc.css');
@import url('https://use.typekit.net/ygz0muq.css');
@import url('https://use.typekit.net/nqz8eyg.css');

* {
  margin: 0 !important;
}

ul {
  list-style-type: none;
}

.text-lg-h1,
.text-lg-h2,
.text-lg-h3,
.text-lg-h4,
.text-lg-h5 {
  font-family: itc-avant-garde-gothic-pro, sans-serif;
}

.text-lg-p1,
.text-lg-p2 {
  font-family: aktiv-grotesk, sans-serif;
}

.text-lg-h1 {
  font-size: 72px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-h2 {
  font-size: 46px;
  font-weight: 400;
  line-height: 1.3;
}

.text-lg-h3 {
  font-size: 33px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.text-lg-h5 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.text-lg-p1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}

.text-lg-p2 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.fixed {
  position: fixed;
}

.indent-75 {
  text-indent: 75px;
}

.indent-150 {
  text-indent: 175px;
}

.hero-bg-img {
  background-image: url('http://localhost:3000/images/pawel-czerwinski-g2Zf3hJyYAc-unsplash.jpg');
  background-size: cover;
  background-attachment: fixed;
}

.clear {
  background-color: rgba(0, 0, 0, 0);
}

.no-click {
  pointer-events: none;
}

.clickable {
  pointer-events: all !important;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.z1000 {
  z-index: 1000;
}

.z8 {
  z-index: 8;
}
.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}
.z5 {
  z-index: 5;
}
.z6 {
  z-index: 6;
}

html,
body {
  overscroll-behavior: none !important;
}

.bw-bottom {
  border-bottom: 1px solid white;
}

.bw-top {
  border-top: 1px solid white;
}

.bw-left {
  border-left: 1px solid white;
}

.bw-right {
  border-right: 1px solid white;
}

.btn-rounded {
  border-radius: 50px;
}

.underline {
  text-decoration: underline;
}

.underline:focus {
  box-shadow: none;
  border: 0;
}

.no-underline,
.no-underline:hover,
.no-underline:focus,
.no-underline:visited {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.open > .dropdown-toggle.btn-outline-white {
  color: #000 !important;
  background-color: rgb(106, 206, 196) !important;
  border-color: rgb(106, 206, 196) !important; /*set the color you want here*/
}

a.text-white:hover {
  color: rgb(106, 206, 196) !important;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.mono {
  font-family: 'Roboto Mono', monospace !important;
}

.hugo-input {
  border: 1px solid white;
  outline: none !important;
}

.hugo-pic {
  width: 225px;
  position: relative;
}

.player-2 {
  font-family: 'Press Start 2P', cursive !important;
}

.from-left::before {
  bottom: -14px !important;
  width: 26px !important;
  height: 10px !important;
  background-color: #fff !important;
  border-right: 4px solid #212529 !important;
  border-left: 4px solid #212529 !important;
}
.from-left::after {
  box-shadow: -4px 0, 4px 0, 4px 4px #fff, 0 4px, 8px 4px, 4px 8px, 8px 8px !important;
  bottom: -18px !important;
  width: 18px !important;
  height: 4px !important;
  margin-right: 8px !important;
  color: #212529 !important;
  background-color: #fff !important;
  left: 36px !important;
}

.nes-balloon {
  margin: 8px !important;
  margin-bottom: 25px !important;

  @include mq-m($mobile-lg) {
    p {
      font-size: 14px !important;
    }
  }
}

.twit {
  max-width: 338px;
}

.bg-shit {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/robotronic/robo.gif);
}

.bg-shit-1 {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/robotronic/robo.gif);
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes blinker {
  // from {
  //   visibility: visible;
  // }
  // to {
  //   visibility: hidden;
  // }

  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
.blinking-text {
  animation: blinker steps(1) 500ms infinite alternate;
}

.static-lg {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/gify/static.gif);
}
.static-palms {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/was-here-2.gif);
  background-repeat: no-repeat;
  background-size: cover;
}
.haduken {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/sf/sf.gif);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 77% center;
}

.frame-zoom {
  height: 200px;
  width: 800px;

  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
