/* NewsTicker.css */
.ticker-container {
  width: 100%;
  overflow: hidden;
  background-color: #000000;
  color: white;
  padding: 10px;
  box-sizing: border-box;
}

.ticker-text {
  white-space: nowrap;
  display: inline-block;
  min-width: 400%; /* Ensure the container is wide enough to hold two copies of the text */
  animation: scroll-left 100s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
