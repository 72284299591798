.tv-knob {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/knob.png);
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 3;
  transform-origin: -10% 5%;
}

.tv-shit {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/tv-w_out-screen.png),
    url(https://minters.s3.us-west-2.amazonaws.com/adz/screen.png);
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 2;
}
.tv-shit-no-bg {
  /* background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/tv-w_out-screen.png),
    url(https://minters.s3.us-west-2.amazonaws.com/adz/screen.png); */
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 2;
}

.tv-channel {
  background-position: center, center;
  background-size: contain, contain;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.static {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/static.gif);
}

.one {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/5.gif);
}

.two {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/4.gif);
}

.three {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/1.gif);
}

.four {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/2.gif);
}

.five {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/3.gif);
}

.six {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/6.gif);
}

.seven {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/7.gif);
}

.eight {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/8.gif);
}

.nine {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/9.gif);
}

.ten {
  background-image: url(https://minters.s3.us-west-2.amazonaws.com/adz/10.gif);
}

.no-monitor {
  height: 100vh !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  font-size: 24px;
}

.loading-container {
  width: 170px;
  height: 15px;
  border: 1px solid white;
  box-shadow: -5px 0 0 0 white, 5px 0 0 0 white, 0 -5px 0 0 white,
    0 5px 0 0 white;
  display: flex;
  align-items: center;
  /* padding: 5px; */
}

.loading-pixel {
  margin: 0 5px !important;
  height: 5px;
  width: 5px;
  background-color: white;
}
