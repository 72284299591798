body {
  height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container {
  width: 60%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

iframe {
  height: 300px;
  width: 400px;
  border: none;
  position: relative;
  left: -82px;
  top: 9px;
}

img {
  position: absolute;
  z-index: 1;
  width: 675px;
  pointer-events: none;
}

iframe {
  html {
    overflow: hidden;
  }
}
